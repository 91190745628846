import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import LoadingIcon from "@/components/loading/loading-icon";
import { addVehicle, getVehicleStatus } from "@/services/fleet-services";
import { FiRefreshCw } from "react-icons/fi";
import { getSites } from "@/services/site-services";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@/components/context/AuthContext";

const AddVehicle = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState<any>([]);
  const [vehicle, setVehicle] = useState({
    code: "",
    description: "",
    brand: "",
    type: "",
    manufacturing_year: "",
    vin: "",
    plate_number: "",
    status: "",
    site: "",
  });
  const [sites, setSites] = useState<any>([]);
  const [isVehicleStatusFetched, setIsVehicleStatusFetched] = useState(false);
  const [isSitesFetched, setIsSitesFetched] = useState(false);
  const { checkPermission } = useAuth();
  const canAddStatus = checkPermission('add_status');
  const canAddSite = checkPermission('add_sites');
  
  useEffect(() => {
    document.title = "Lazem GO - Add Vehicle"
  }, []);

  const fetchVehicleStatus = async () => {
    setIsVehicleStatusFetched(true);
    // Fetch Vehicle Status
    await getVehicleStatus()
      .then((response) => {
        setVehicleStatus(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsVehicleStatusFetched(false);
  };

  const fetchSites = async () => {
    setIsSitesFetched(true);
    await getSites()
      .then((response) => {
        setSites(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsSitesFetched(false);
      });
  };

  const functionToCall = (field: string) => {
    if (field === "status") {
      fetchVehicleStatus();
    }
    if (field === "site") {
      fetchSites();
    }
  };

  const fetchData = async () => {
    await fetchVehicleStatus();
    await fetchSites();
  };

  const { isLoading: siteLoading } = useQuery({
    queryKey: ["add vehicle"],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
  });

  const setInputField = (
    label: string,
    field: string,
    value: any,
    type?: string
  ) => {
    return (
      <div className="flex flex-col my-4">
        <Label className="mb-2">{label}</Label>
        <Input
          type={type ? type : "text"}
          value={value}
          onChange={(e) => setVehicle({ ...vehicle, [field]: e.target.value })}
        />
      </div>
    );
  };

  const setSelectField = (
    label: string,
    field: string,
    options: any,
    with_add?: boolean,
    url?: string,
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">{label}</Label>
        <div className="flex gap-2">
          <div className={`${with_add ? "w-4/5 " : "w-full"}`}>
            <Select
              onValueChange={(value: any) =>
                setVehicle({ ...vehicle, [field]: value })
              }
              disabled={isVehicleStatusFetched}
            >
              <SelectTrigger>
                <SelectValue
                  placeholder={
                    language === "en" ? "Select" + label : "اختر" + label
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>
                    {language === "en" ? "Select" + label : "اختر" + label}
                  </SelectLabel>
                  {options?.map((option: any) => (
                    <SelectItem key={option.id} value={option.id}>
                      {field === "status" ? option.title : option.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div>
            {with_add && (
              <Button
                variant="outline"
                onClick={() => functionToCall(field)}
                className=""
              >
                <FiRefreshCw />
              </Button>
            )}
          </div>
        </div>
        {with_add && (
          <Button
            variant="link"
            className="text-sm flex justify-start w-fit"
            onClick={() => window.open(url ? url : "", "_blank")}
          >
            {language === "en" ? "Add New" : "إضافة جديد"}
          </Button>
        )}
      </div>
    );
  };

  const handleSubmit = async () => {
    if (checkForm()) {
      setFormLoading(true);
      // console.log(vehicle);
      addVehicle(vehicle)
        .then((response) => {
          navigate("/fleet/id/" + response.data.id);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setFormLoading(false);
        });
    }
  };

  const checkForm = () => {
    if (vehicle.brand === "") {
      setFormError(language === "en" ? "Brand is required" : "الماركة مطلوبة");
      return false;
    } else if (vehicle.type === "") {
      setFormError(language === "en" ? "Type is required" : "النوع مطلوب");
      return false;
    } else if (vehicle.manufacturing_year === "") {
      setFormError(
        language === "en"
          ? "Manufacturing Year is required"
          : "سنة الصنع مطلوبة"
      );
      return false;
    } else if (vehicle.vin === "") {
      setFormError(language === "en" ? "VIN is required" : "رقم الهيكل مطلوب");
      return false;
    } else if (vehicle.plate_number === "") {
      setFormError(
        language === "en" ? "Plate Number is required" : "رقم اللوحة مطلوب"
      );
      return false;
    }
    return true;
  };

  return (
    <div>
      {siteLoading === true && <LoadingScreen />}
      {
        <div dir={language === "en" ? "ltr" : "rtl"}>
          <Card className="w-full max-w-xl mx-auto mt-8">
            <CardHeader>
              <div className="flex justify-start mb-4">
                <Button
                  onClick={() => navigate("/fleet")}
                  className="my-4 w-full"
                  variant={"secondary"}
                >
                  {language === "en" ? "Back" : "رجوع"}
                </Button>
              </div>
              <CardTitle>
                {language === "en" ? "Vehicle Information" : "معلومات المركبة"}
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col text-start">
              {setInputField(
                language === "en" ? "Code" : "الكود",
                "code",
                vehicle.code
              )}
              {setInputField(
                language === "en" ? "Description" : "الوصف",
                "description",
                vehicle.description
              )}
              {setInputField(
                language === "en" ? "Brand" : "الماركة",
                "brand",
                vehicle.brand
              )}
              {setInputField(
                language === "en" ? "Type" : "النوع",
                "type",
                vehicle.type
              )}
              {setInputField(
                language === "en" ? "Manufacturing Year" : "سنة الصنع",
                "manufacturing_year",
                vehicle.manufacturing_year,
                "date"
              )}
              {setInputField(
                language === "en" ? "VIN" : "رقم الهيكل",
                "vin",
                vehicle.vin
              )}
              {setInputField(
                language === "en" ? "Plate Number" : "رقم اللوحة",
                "plate_number",
                vehicle.plate_number
              )}

              {isVehicleStatusFetched ? (
                <LoadingIcon />
              ) : (
                <>
                  {setSelectField(
                    language === "en" ? "Status" : "الحالة",
                    "status",
                    vehicleStatus,
                    canAddStatus,
                    "/fleet/create-status"
                  )}
                </>
              )}
              {isSitesFetched ? (
                <LoadingIcon />
              ) : (
                <>
                  {setSelectField(
                    language === "en" ? "Site" : "الموقع",
                    "site",
                    sites,
                    canAddSite,
                    "/sites/create"
                  )}
                </>
              )}
              {formError !== "" && (
                <div className="text-red-500">{formError}</div>
              )}
              <Button onClick={handleSubmit} className="mt-4">
                {formLoading === true ? (
                  <LoadingIcon />
                ) : language === "en" ? (
                  "Submit"
                ) : (
                  "إرسال"
                )}
              </Button>
            </CardContent>
          </Card>
        </div>
      }
    </div>
  );
};

export default AddVehicle;
