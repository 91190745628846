import { useAuth } from "@/components/context/AuthContext";
import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { useState } from "react";
import { CiSettings } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const SelectSupportRequest = () => {
    const { language } = useLanguage();
    const { checkPermission } = useAuth();
    const navigate = useNavigate();
    const [supportlist] = useState([
        {
            title_en: "On Boarding",
            title_ar: "بدء الخدمة",
            route: "/special-request/onboarding",
            isActive: checkPermission('view_onboarding')
        },
        {
            title_en: "Off Boarding",
            title_ar: "إنهاء الخدمة",
            route: "/special-request/offboarding",
            isActive: checkPermission('view_offboarding')
        },
        {
            title_en: "Add Training Certificate",
            title_ar: "إضافة شهادة تدريب",
            route: "/special-request/add-training-certificate",
            isActive: checkPermission('view_trainingcertificate')
        },
    ])

    return (
        <div
            dir={language === 'en' ? 'ltr' : 'rtl'}
        >
            <div>
                <h1 className="text-3xl font-bold text-center m-auto mt-10">
                    {language === 'en' ? "Select Support Request" : "اختر طلب الدعم"}
                </h1>
                <div
                    className="flex justify-between items-end mx-6 my-4 gap-4"
                >
                    <Button
                        onClick={() => navigate("/")}
                        variant={"secondary"}
                    >
                        {language === 'en' ? "Home Page" : "الصفحة الرئيسية"}
                    </Button>

                    {
                        checkPermission('view_supprtsettings') &&
                        <div
                            className="flex gap-4 flex-wrap"
                        >
                            <CiSettings
                                size={40}
                                className="cursor-pointer my-auto"
                                onClick={() => navigate("/special-request/settings")}
                            />
                        </div>
                    }

                </div>

            </div>
            <div
                className="flex flex-wrap mx-auto mt-10 gap-6 p-4 justify-content-center"
            >
                {supportlist.map((service, index) => (
                    service.isActive &&
                    <div
                        key={index}
                        className="m-auto"
                    >
                        {
                            <Card
                                className="m-3 w-64 h-64 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                                onClick={() => navigate(service.route)}
                            >
                                <CardHeader
                                    className="h-1/3 w-full flex justify-content-center m-auto"
                                >
                                    <CardTitle
                                        className="text-2xl font-bold flex justify-content-center m-auto"
                                    >
                                        {language === 'en' ? service.title_en : service.title_ar}
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        }
                    </div>
                ))
                }
            </div>
        </div>
    )
}

export default SelectSupportRequest;