import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { useLanguage } from "./context/LanguageContext";
import { useState } from "react";
import { FcSupport } from "react-icons/fc";
import { MdOutlineFiberNew } from "react-icons/md";
import { MdOutlineSelfImprovement } from "react-icons/md";
import { MdTipsAndUpdates } from "react-icons/md";

const WhatIsNew = () => {
    const { language } = useLanguage();
    const [news, setNews] = useState<any>({
        title: language === "en" ? "What's new in React 18?" : "ما الجديد في React 18؟",
        version: "18.0.0",
        release_date: "2022-01-01",
        update_details: [
        {
            title: language === "en" ? "New Features" : "ميزات جديدة",
            icon: <MdOutlineFiberNew size={40}/>,
            content:[
                {
                    title: language === "en" ? "Feature 1" : "ميزة 1",
                    description: language === "en" ? "Description 1" : "وصف 1",
                },
                {
                    title: language === "en" ? "Feature 2" : "ميزة 2",
                    description: language === "en" ? "Description 2" : "وصف 2",
                },
                {
                    title: language === "en" ? "Feature 2" : "ميزة 2",
                    description: language === "en" ? "Description 2" : "وصف 2",
                },
                                {
                    title: language === "en" ? "Feature 2" : "ميزة 2",
                    description: language === "en" ? "Description 2" : "وصف 2",
                },
            ]
        },
        {
            title: language === "en" ? "Bug Fixes" : "إصلاحات الأخطاء",
            icon: <FcSupport size={40}/>,
            content:[
                {
                    title: language === "en" ? "Bug 1" : "خلل 1",
                    description: language === "en" ? "Description 1" : "وصف 1",
                },
                {
                    title: language === "en" ? "Bug 2" : "خلل 2",
                    description: language === "en" ? "Description 2" : "وصف 2",
                },
            ]
        },
        {
            title: language === "en" ? "Improvements" : "تحسينات",
            icon: <MdOutlineSelfImprovement size={40}/>,
            content:[
                {
                    title: language === "en" ? "Improvement 1" : "تحسين 1",
                    description: language === "en" ? "Description 1" : "وصف 1",
                },
                {
                    title: language === "en" ? "Improvement 2" : "تحسين 2",
                    description: language === "en" ? "Description 2" : "وصف 2",
                },
            ]
        },
        {
            title: language === "en" ? "Updates" : "تحديثات",
            icon: <MdTipsAndUpdates size={40}/>,
            content:[
                {
                    title: language === "en" ? "Update 1" : "تحديث 1",
                    description: language === "en" ? "Description 1" : "وصف 1",
                },
                {
                    title: language === "en" ? "Update 2" : "تحديث 2",
                    description: language === "en" ? "Description 2" : "وصف 2",
                },
            ]
        }
    ]
});
  return (
    <div
        dir={language === "en" ? "ltr" : "rtl"}
    >
        <>
            <CardHeader>
            <CardTitle>{news.title}</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {news.update_details.map((detail: any) => (
                        <Card key={detail.title}>
                            <CardHeader>
                                <CardTitle
                                    className="text-center flex flex-col items-center"
                                >
                                    <div 
                                        className="mb-4 "
                                    >
                                        {detail.icon}
                                    </div>
                                    {detail.title}
                                </CardTitle>
                            </CardHeader>
                            <CardContent className="text-start">
                                <div className="mb-4">
                                    {detail.content.map((content: any) => (
                                        <ul>
                                            <li
                                                className="!list-disc"
                                            >
                                                <p
                                                    className="font-bold !list-disc"
                                                >
                                                    {content.title}
                                                </p>
                                                <p>{content.description}</p>
                                            </li>
                                        </ul>
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </CardContent>
        </>
    </div>
  );
}

export default WhatIsNew;