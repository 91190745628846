import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import LoadingIcon from "@/components/loading/loading-icon";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import { users } from "@/services/user-service";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { tickets, categories } from "@/services/tickets-services";

const OffboardingForm = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { id } = useParams();
  const [listOfReasons, setListOfReasons] = useState([
    {
      title_en: "End of contract",
      title_ar: "نهاية العقد",
    },
    {
      title_en: "Resignation",
      title_ar: "استقالة",
    },
    {
      title_en: "Retirement",
      title_ar: "تقاعد",
    },
    {
      title_en: "Layoff",
      title_ar: "فصل",
    },
    {
      title_en: "Termination for Cause",
      title_ar: "إنهاء لسبب",
    },
    {
      title_en: "Mutual Agreement",
      title_ar: "اتفاق متبادل",
    },
    {
      title_en: "Poor Cultural Fit",
      title_ar: "عدم توافق ثقافي",
    },
    {
      title_en: "Poor Performance",
      title_ar: "أداء سيء",
    },
    {
      title_en: "Job Dissatisfaction",
      title_ar: "عدم رضا عن الوظيفة",
    },
    {
      title_en: "Personal Reasons",
      title_ar: "أسباب شخصية",
    },
    {
      title_en: "Others",
      title_ar: "أخرى",
    },
  ]);
  const [selectedReason, setSelectedReason] = useState("");
  const [note, setNote] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [offboardingForm, setOffboardingForm] = useState({
    title: "Off Boarding",
    service_type: "offboarding",
    description: "Off boarding",
    priority: "High",
    category: null,
    json_request: [
      {
        question_en: "Reason",
        question_ar: "السبب",
        answer: "",
        name: "reason",
        is_required: true,
      },
      {
        question_en: "Note",
        question_ar: "ملاحظة",
        answer: "",
        name: "note",
        is_required: false,
      },
    ],
  });
  const { data: userData, isLoading: userLoading } = useQuery({
    queryKey: ["user", id],
    queryFn: () => users.get(id),
    refetchOnWindowFocus: false,
  });

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
} = useQuery({
    queryKey: ["categories onboarding"],
    queryFn: ()=> categories.getWithFilter("tag=on_boarding").then((response) => {
      console.log('filter',response);
      if (response.data.length !== 0) {
        setOffboardingForm({
          ...offboardingForm,
          category: response.data[0].id,
        });
      }
      return response.data;
    }),
    refetchOnWindowFocus: false,
});

  const handleSubmit = async () => {
    setIsSubmitting(true);
    tickets.create(offboardingForm).then((response) => {
      setIsSubmitting(false);
      navigate("/my-requests/id/" + response.data.id);
    }).catch((error) => {
      console.log("error", error);
    });
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {(userLoading || categoriesLoading) && <LoadingScreen />}
      {userData && (
        <div>
          <h1 className="text-3xl font-bold text-center m-auto my-10">
            {language === "en" ? "User Offboarding" : "إنهاء عمل المستخدم"}
          </h1>
          <Card className="w-11/12 m-auto mt-10 lg:w-1/2">
            <Button
              onClick={() => navigate("/special-request/offboarding")}
              className="my-4 w-11/12"
              variant={"secondary"}
            >
              {language === "en" ? "Back" : "عودة"}
            </Button>
            <Dialog>
              <DialogTrigger className="w-full">
                <div className="w-full flex justify-between flex-wrap p-4">
                  <Button variant={"destructive"} className="w-fit">
                    {language === "en" ? "Offboard User" : "إنهاء عمل المستخدم"}
                  </Button>
                </div>
              </DialogTrigger>
              <DialogContent
                dir={language === "en" ? "ltr" : "rtl"}
                className="text-start"
              >
                <DialogHeader>
                  <DialogTitle className="text-xl font-bold text-center">
                    {language === "en" ? "Offboard User" : "إنهاء عمل المستخدم"}
                  </DialogTitle>
                </DialogHeader>
                <DialogDescription className="text-center">
                  {language === "en"
                    ? "Fill in the form below to offboard the user"
                    : "املأ النموذج أدناه لإنهاء عمل المستخدم"}
                </DialogDescription>
                <Label>{language === "en" ? "Reason" : "السبب"}</Label>
                <Select
                  value={selectedReason}
                  onValueChange={(value) => {
                    setSelectedReason(value);
                    setOffboardingForm({
                      ...offboardingForm,
                      json_request: offboardingForm.json_request.map((item) => {
                        if (item.name === "reason") {
                          item.answer = value;
                        }
                        return item;
                      }),
                    });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue
                      placeholder={
                        language === "en" ? "Select Reason" : "حدد السبب"
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {listOfReasons.map((reason, index) => (
                      <SelectGroup key={index}>
                        <SelectItem value={reason.title_en}>
                          {language === "en"
                            ? reason.title_en
                            : reason.title_ar}
                        </SelectItem>
                      </SelectGroup>
                    ))}
                  </SelectContent>
                </Select>
                <Label>{language === "en" ? "Note" : "ملاحظة"}</Label>
                <Textarea
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                    setOffboardingForm({
                      ...offboardingForm,
                      json_request: offboardingForm.json_request.map((item) => {
                        if (item.name === "note") {
                          item.answer = e.target.value;
                        }
                        return item;
                      }),
                    });
                  }}
                />
                <Button 
                  disabled = {isSubmitting}
                  onClick={handleSubmit}
                >
                  {isSubmitting ? (
                    <LoadingIcon />
                  ) : language === "en" ? (
                    "Offboard"
                  ) : (
                    "إنهاء العمل"
                  )}
                </Button>
              </DialogContent>
            </Dialog>
            <CardContent className="w-full text-start">
              <h2>
                {language === "en" ? "User Information" : "معلومات المستخدم"}
              </h2>
              <div className="grid gap-4">
                <div className="w-full">
                  <Label>
                    {language === "en" ? "First Name" : "الاسم الأول"}
                  </Label>
                  <Input value={userData.data.first_name} disabled />
                </div>
                <div>
                  <Label>
                    {language === "en" ? "Middle Name" : "الاسم الأوسط"}
                  </Label>
                  <Input value={userData.data.middle_name} disabled />
                </div>
                <div>
                  <Label>
                    {language === "en" ? "Last Name" : "الاسم الأخير"}
                  </Label>
                  <Input value={userData.data.last_name} disabled />
                </div>
                <div>
                  <Label>
                    {language === "en" ? "Email" : "البريد الإلكتروني"}
                  </Label>
                  <Input value={userData.data.email} disabled />
                </div>
                <div>
                  <Label>{language === "en" ? "Department" : "قسم"}</Label>
                  <Input
                    value={userData?.data?.staff_profile?.department?.title}
                    disabled
                  />
                </div>
                <div>
                  <Label>{language === "en" ? "Role" : "المسمى الوظيفي"}</Label>
                  <Input
                    value={userData?.data?.staff_profile?.role?.title}
                    disabled
                  />
                </div>
                <div>
                  <Label>{language === "en" ? "Phone" : "هاتف"}</Label>
                  <Input
                    value={userData?.data?.profile_user?.phone_number}
                    disabled
                  />
                </div>
                <div>
                  <Label>{language === "en" ? "Address" : "عنوان"}</Label>
                  <Input
                    value={userData?.data?.profile_user?.address}
                    disabled
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};

export default OffboardingForm;
