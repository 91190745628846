import { Logs } from "@/services/log-services";
import { useQuery } from "@tanstack/react-query";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import LoadingScreen from "@/components/loading/loading-screen";
import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";

const SystemLogsDashboard = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { language } = useLanguage();
  const [currentPage, setCurrentPage] = useState<any>(null);
  const [pageCount, setPageCount] = useState<any>(0);

  useEffect(() => {
    const pageParam = searchParams.get("page");
    if (pageParam) {
      setCurrentPage(pageParam);
    }
  }, [searchParams]);

  const {
    data: logsData,
    isLoading: logsLoading,
    refetch: refetchLogs,
    isFetching: logsFetching,
  } = useQuery({
    queryKey: ["logs", currentPage, searchParams],
    queryFn: () =>
      Logs.list(currentPage).then((response) => {
        let count = (response?.count / response?.results?.length).toFixed(0);
        setPageCount(count);
        return response;
      }),
    refetchOnWindowFocus: false,
  });

  const handleNextPage = () => {
    if (logsData?.next) {
      searchParams.set("page", logsData.next.split("=")[1]);
      navigate(`?${searchParams.toString()}`);
      setCurrentPage(logsData.next);
      refetchLogs();
    }
  };

  const handlePreviousPage = () => {
    if (logsData?.previous) {
      searchParams.set("page", logsData.previous.split("=")[1] || 1);
      navigate(`?${searchParams.toString()}`);
      setCurrentPage(logsData.previous);
      refetchLogs();
    }
  };

  const handleSearch = (page: any) => {
    searchParams.set("page", page);
    navigate(`?${searchParams.toString()}`);
    setCurrentPage(page);
    refetchLogs();
  }

  return (
    <div>
      {(logsLoading || logsFetching) && <LoadingScreen />}
      <h1 className="text-3xl font-bold text-center my-4">
        {language === "en" ? "System Logs" : "سجلات النظام"}
      </h1>
      {logsData && (
        <Pagination>
          <PaginationPrevious
            onClick={handlePreviousPage}
            isActive={logsData.previous !== null}
          >
            {language === "en" ? "Previous" : "السابق"}
          </PaginationPrevious>
          <PaginationContent>
            {logsData.previous && logsData.previous !== null && (
              <PaginationItem>
                <PaginationLink
                  onClick={() => {
                    handleSearch(Number(logsData?.previous?.split("=")[1]) - 1 || 1);
                  }}
                >
                  {Number(logsData.previous.split("=")[1]) - 1 || 1}
                </PaginationLink>
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink
                onClick={() => {
                  handleSearch(logsData.previous.split("=")[1]);
                }}
              >
                {(logsData.previous && logsData.previous !== null) &&
                  logsData.previous.split("=")[1]
                }
              </PaginationLink>
            </PaginationItem>
            <PaginationEllipsis />
            {logsData.next && logsData.next.split("=")[1] !== pageCount && (
              <PaginationItem>
                <PaginationLink
                  onClick={() => {
                    handleSearch(logsData.next.split("=")[1]);
                  }}
                >
                  {logsData.next.split("=")[1]}
                </PaginationLink>
              </PaginationItem>
            )}
            {
              (logsData?.count !== currentPage) &&

              <PaginationItem>
                <PaginationLink
                  onClick={() => {
                    handleSearch(pageCount);
                  }}
                >
                  {pageCount}
                </PaginationLink>
              </PaginationItem>
            }
          </PaginationContent>
          <PaginationNext
            onClick={handleNextPage}
            isActive={logsData.next !== null}
          >
            {language === "en" ? "Next" : "التالي"}
          </PaginationNext>
        </Pagination>
      )}
      <div className="flex justify-center items-center my-4 w-2/5 mx-auto ">
        <Input
          type="text"
          placeholder={language === "en" ? "Search by page" : "البحث بالصفحة"}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              console.log("search", e.target.value);
              setCurrentPage(e.target.value);
              refetchLogs();
            }
          }}
        />
      </div>
      <div>
        <Table>
          <TableCaption>
            {language === "en" ? "System Logs" : "سجلات النظام"}
          </TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead>{language === "en" ? "ID" : "معرف"}</TableHead>
              <TableHead>
                {language === "en" ? "Added On" : "أضيفت في"}
              </TableHead>
              <TableHead>
                {language === "en" ? "Client IP Address" : "عنوان IP للعميل"}
              </TableHead>
              <TableHead>
                {language === "en" ? "Execution Time" : "وقت التنفيذ"}
              </TableHead>
              <TableHead>
                {language === "en" ? "Status Code" : "كود الحالة"}
              </TableHead>
              <TableHead>
                {language === "en" ? "Execution Time" : "وقت التنفيذ"}
              </TableHead>
              <TableHead>{language === "en" ? "Headers" : "هيدرز"}</TableHead>
              <TableHead>
                {language === "en" ? "Response" : "استجابة"}
              </TableHead>
              <TableHead>{language === "en" ? "Body" : "بودي"}</TableHead>
              <TableHead>{language === "en" ? "API" : "API"}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {logsData &&
              logsData?.results?.map((log: any) => (
                <>
                  <TableRow
                    key={log.id}
                    className="cursor-pointer text-truncate overflow-hidden"
                  >
                    <TableCell>
                      <Accordion type="multiple" asChild>
                        <AccordionItem value={log.id}>
                          <AccordionTrigger>
                            <div className="text-clip overflow-hidden w-[100px] h-[30px] text-xs">
                              {log.id}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div>{log.id}</div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </TableCell>

                    <TableCell>
                      <Accordion type="multiple" asChild>
                        <AccordionItem value={log.id}>
                          <AccordionTrigger>
                            <div className="text-clip overflow-hidden w-[100px] h-[30px] text-xs">
                              {log.client_ip_address}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div>{log.client_ip_address}</div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </TableCell>

                    <TableCell>
                      <Accordion type="multiple" asChild>
                        <AccordionItem value={log.id}>
                          <AccordionTrigger>
                            <div className="text-clip overflow-hidden w-[200px] h-[30px] text-xs">
                              {log.added_on}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div>{log.added_on}</div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </TableCell>

                    <TableCell>
                      <Accordion type="multiple" asChild>
                        <AccordionItem value={log.id}>
                          <AccordionTrigger>
                            <div className="text-clip overflow-hidden w-[100px] h-[30px] text-xs">
                              {log.execution_time}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div>{log.execution_time}</div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </TableCell>

                    <TableCell>
                      <Accordion type="multiple" asChild>
                        <AccordionItem value={log.id}>
                          <AccordionTrigger>
                            <div className="text-clip overflow-hidden w-[50px] h-[30px] text-xs">
                              {log.status_code}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div>{log.status_code}</div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </TableCell>

                    <TableCell>
                      <Accordion type="multiple" asChild>
                        <AccordionItem value={log.id}>
                          <AccordionTrigger>
                            <div className="text-clip overflow-hidden w-[50px] h-[30px] text-xs">
                              {log.execution_time}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div>{log.execution_time}</div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </TableCell>

                    <TableCell>
                      <Accordion type="multiple" asChild>
                        <AccordionItem value={log.id}>
                          <AccordionTrigger>
                            <div className="text-clip overflow-hidden w-[200px] h-[30px] text-xs">
                              {log.headers}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div>{log.headers}</div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </TableCell>

                    <TableCell>
                      <Accordion type="multiple" asChild>
                        <AccordionItem value={log.id}>
                          <AccordionTrigger>
                            <div className="text-clip overflow-hidden w-[200px] h-[30px] text-xs">
                              {log.response}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div>{log.response}</div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </TableCell>

                    <TableCell>
                      <Accordion type="multiple" asChild>
                        <AccordionItem value={log.id}>
                          <AccordionTrigger>
                            <div className="text-clip overflow-hidden w-[200px] h-[30px] text-xs">
                              {log.body}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div>{log.body}</div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </TableCell>


                    <Accordion type="multiple" asChild>
                      <AccordionItem value={log.id}>
                        <AccordionTrigger>
                          <div className="text-clip overflow-hidden w-[300px] h-[30px] text-xs">
                            {log.api}
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div>{log.api}</div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default SystemLogsDashboard;
