import { useLanguage } from "@/components/context/LanguageContext";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { projects, getStages } from "@/services/project-service";
import { useQuery } from "@tanstack/react-query";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { CiSettings } from "react-icons/ci";

const ProjectDashboard = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [fetchedProjects, setFetchProjects] = useState<any>();
  const [projectStages, setProjectStages] = useState<any>();
  // const [loading, setLoading] = useState<boolean>(fale);


  useEffect(() => {
    document.title = "Lazem GO - Projects Dashboard";
  }, []);

  const getData = async () => {
    // if(!projects){
    projects
      .list()
      .then((response) => {
        // console.log(response.data);
        setFetchProjects(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
    await getStages()
      .then((response) => {
        const sortedStages = response.data.sort((a: any, b: any) => {
          return a.index - b.index;
        });
        setProjectStages(sortedStages);
      })
      .catch((error) => {
        console.log("Error", error);
      });
    // };
  };

  const { isLoading: projectsLoading } = useQuery({
    queryKey: ["projects dashboard"],
    queryFn: getData,
    refetchOnWindowFocus: false,
  });


  const getProjectsByStage = (stageId: number) => {
    return fetchedProjects.filter(
      (project: any) => project?.stage?.id === stageId
    );
  };

  return (
    <>
      {projectsLoading === true && <LoadingScreen />}
      {
        <>
          {fetchedProjects && projectStages && (
            <div dir={language === "en" ? "ltr" : "rtl"}>
              <Card className="fixed top-0 z-10 shadow-md w-full right-0 left-0 h-[7rem]">
                <h1 className="text-2xl font-bold mt-2">
                  {language === "en" ? "Projects" : "المشاريع"}
                </h1>
                <div className="flex ml-auto mr-6 mb-4 w-4/12 xl:w-3/12 lg:w-4/12 gap-4 ">
                      <Button
                        variant="secondary"
                        className="my-4"
                        permission="change_projects"
                        onClick={() => navigate("/projects/settings")}
                      >
                        <CiSettings
                          size={40}
                          className="cursor-pointer"
                        />
                      </Button>
                    <Button
                      onClick={() => navigate("/projects/create")}
                      className="my-4"
                      permission="add_projects"
                    >
                      {language === "en" ? "Create Project" : "إنشاء مشروع"}
                    </Button>
                  <Button
                    onClick={() => navigate("/")}
                    className="my-4"
                    variant="secondary"
                  >
                    {language === "en" ? "Home Page" : "الصفحة الرئيسية"}
                  </Button>
                </div>
              </Card>
              <div className="p-4">
                <ScrollArea>
                  <ScrollBar orientation="horizontal" />
                  <div className="gap-4 hidden mt-[7rem] lg:flex">
                    {projectStages.map((stage: any) => (
                      <div
                        key={stage.id}
                        className="flex-shrink-0 w-[320px] min-h-screen"
                      >
                        <Card className="h-full">
                          <CardHeader>
                            <CardTitle>{stage.title}</CardTitle>
                            <CardDescription>
                              {stage.description}
                            </CardDescription>
                          </CardHeader>
                          <CardContent className="">
                            {getProjectsByStage(stage.id).map(
                              (project: any) => (
                                <Card
                                  key={project.id}
                                  className="mb-4 cursor-pointer"
                                  onClick={() =>
                                    navigate(`/projects/id/${project.id}/`)
                                  }
                                >
                                  <CardHeader>
                                    <CardTitle>{project.title}</CardTitle>
                                    <CardDescription>
                                      {project.description}
                                    </CardDescription>
                                  </CardHeader>
                                  <CardContent>
                                    <p>
                                      {language === "en"
                                        ? "Start Date"
                                        : "تاريخ البدء"}
                                      : {project?.start_date?.substring(0, 10)}
                                    </p>
                                    <p>
                                      {language === "en"
                                        ? "End Date"
                                        : "تاريخ الانتهاء"}
                                      : {project?.end_date?.substring(0, 10)}
                                    </p>
                                    <p>
                                      {language === "en" ? "Manager" : "مدير"}:{" "}
                                      {project?.project_manager?.full_name}
                                    </p>
                                  </CardContent>
                                </Card>
                              )
                            )}
                          </CardContent>
                        </Card>
                      </div>
                    ))}
                  </div>
                  <div className="lg:hidden md:block sm:block mt-[7rem]">
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>
                            {language === "en" ? "Title" : "العنوان"}
                          </TableHead>
                          <TableHead>
                            {language === "en" ? "Description" : "الوصف"}
                          </TableHead>
                          <TableHead>
                            {language === "en" ? "Start Date" : "تاريخ البدء"}
                          </TableHead>
                          <TableHead>
                            {language === "en" ? "End Date" : "تاريخ الانتهاء"}
                          </TableHead>
                          <TableHead>
                            {language === "en" ? "Manager" : "مدير"}
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {fetchedProjects.map((project: any) => (
                          <TableRow
                            key={project.id}
                            onClick={() =>
                              navigate(`/projects/id/${project.id}/`)
                            }
                          >
                            <TableCell>{project.title}</TableCell>
                            <TableCell>{project.description}</TableCell>
                            <TableCell>
                              {project?.start_date?.substring(0, 10)}
                            </TableCell>
                            <TableCell>
                              {project?.end_date?.substring(0, 10)}
                            </TableCell>
                            <TableCell>
                              {project?.project_manager?.full_name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </ScrollArea>
              </div>
            </div>
          )}
        </>
      }
    </>
  );
};

export default ProjectDashboard;
