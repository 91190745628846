import { useLanguage } from "@/components/context/LanguageContext";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  getSite,
  editSite,
  getSiteMembers,
  createSiteMember,
  deleteSite,
} from "@/services/site-services";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { getAllUsers } from "@/services/user-service";
import { MdDeleteForever } from "react-icons/md";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

const ManageSite = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { id } = useParams();
  // const [site, setSite] = useState<any>();
  const [updatedFields, setUpdatedFields] = useState<any>();
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isSiteMembersEditing, setIsSiteMembersEditing] = useState(false);
  const [newSiteMembers, setNewSiteMembers] = useState<any>({
    site: id,
    user: "",
    role: "",
  });

  useEffect(() => {
    document.title = "Lazem GO - Manage Site";
  }, []);

  const fetchSite = async () => {
    return await getSite(id)
      .then((response: any) => {
        // console.log(response.data);
        return response.data;
        // setSite(response.data);
      })
      .catch((error: any) => {
        console.log("Error", error);
      });
  };

  const {
    isLoading: siteLoading,
    refetch: refetchSites,
    isFetching: siteFetching,
    data: site,
  } = useQuery({
    queryKey: ["site edit"],
    queryFn: fetchSite,
    refetchOnWindowFocus: false,
  });

  const { data: usersData, isLoading: usersLoading } = useQuery({
    queryKey: ["users"],
    queryFn: () => {
      return getAllUsers().then((response: any) => {
        return response.data;
      });
    },
    refetchOnWindowFocus: false,
  });

  const setInputField = (
    label: string,
    field: string,
    value: any,
    type?: string
  ) => {
    return (
      <div className="flex flex-col my-4 text-start">
        <Label className="mb-2">{label}</Label>
        <Input
          type={type ? type : "text"}
          defaultValue={value}
          onChange={(e) => {
            const newValue = e.target.value;
            setUpdatedFields((prevFields: any) => ({
              ...prevFields,
              [field]: newValue,
            }));
            queryClient.setQueryData(["site edit"], (oldData: any) => {
              if (oldData) {
                return {
                  ...oldData,
                  [field]: newValue,
                };
              } else {
                return oldData;
              }
            });
          }}
          disabled={!isEditing}
        />
      </div>
    );
  };

  const setSelectField = (label: string, field: string, options: any) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">{label}</Label>
        <div className="flex gap-2">
          <div className={"w-full"}>
            <Select
              onValueChange={(value: any) => {
                // setProject({ ...project, [field]: value })
                setNewSiteMembers({ ...newSiteMembers, [field]: value });
              }}
              disabled={!isSiteMembersEditing}
              defaultValue={newSiteMembers[field] || ""}
            >
              <SelectTrigger>
                <SelectValue
                  placeholder={
                    language === "en" ? "Select" + label : "اختر" + label
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>
                    {language === "en" ? "Select" + label : "اختر" + label}
                  </SelectLabel>
                  {options?.map((option: any) => (
                    <SelectItem key={option.id} value={option.id}>
                      {option?.first_name +
                        " " +
                        (option?.middle_name || "") +
                        " " +
                        (option?.last_name || "")}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    );
  };

  const checkSiteMembersChanges = () => {
    // console.log(newSiteMembers);
    if (newSiteMembers === null || newSiteMembers === undefined) {
      setFormError(
        language === "en" ? "No changes detected" : "لم يتم الكشف عن أي تغيير"
      );
      return false;
    } else if (newSiteMembers.user === "" || newSiteMembers.role === "") {
      setFormError(
        language === "en"
          ? "User and Role are required"
          : "المستخدم والدور مطلوبين"
      );
      return false;
    } else if (
      site.site_members.filter(
        (member: any) => member.user.id === newSiteMembers.user
      ).length > 0
    ) {
      setFormError(
        language === "en" ? "User already exists" : "المستخدم موجود بالفعل"
      );
      return false;
    } else {
      return true;
    }
  };

  const submitSiteMembers = async () => {
    if (!checkSiteMembersChanges()) {
      return;
    }
    setFormLoading(true);
    await createSiteMember(newSiteMembers)
      .then(() => {
        refetchSites();
        setIsSiteMembersEditing(false);
        setNewSiteMembers({
          site: id,
          user: "",
          role: "",
        });
        setFormError("");
      })
      .catch((error: any) => {
        console.log("Error", error);
      });
    setFormLoading(false);
  };
  const addSiteMembersComponent = () => {
    // console.log("usersData ", usersData);
    return (
      <>
        {setSelectField(
          language === "en" ? "User" : "المستخدم",
          "user",
          usersData
        )}
        {setSelectField(language === "en" ? "Role" : "الدور", "role", [
          { id: "manager", first_name: "manager" },
          { id: "member", first_name: "member" },
        ])}
        {formError !== "" && (
          <p className="text-red-500 font-semibold text-start mb-4">
            {formError}
          </p>
        )}
        {isSiteMembersEditing && (
          <Button
            onClick={() => {
              submitSiteMembers();
            }}
            permission="add_sitemembers"
          >
            {formLoading ? (
              <LoadingIcon />
            ) : language === "en" ? (
              "Add Site Member"
            ) : (
              "إضافة عضو الموقع"
            )}
          </Button>
        )}
      </>
    );
  };

  const deleteSiteMember = async (id: any) => {
    await deleteSite(id)
      .then(() => {
        refetchSites();
      })
      .catch((error: any) => {
        console.log("Error", error);
      });
  };

  const siteMembersComponent = (members: any) => {
    return (
      <>
        {members.map((member: any) => (
          <div
            key={member.id}
            className="flex justify-between items-center border-b border-gray-200 py-2"
          >
            {/* delete diolog */}
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  permission="delete_sitemembers"
                  variant={"secondary"}
                >
                  <MdDeleteForever
                    className="text-red-500 cursor-pointer"
                    size={20}
                  />
                </Button>

              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle className="text-center">
                    {language === "en" ? (
                      <>
                        Are you sure you want to delete
                        <br />
                        <br />
                        {member.user.full_name}?
                      </>
                    ) : (
                      <>
                        هل أنت متأكد أنك تريد حذف
                        <br />
                        <br />
                        {member.user.full_name}؟
                      </>
                    )}
                  </DialogTitle>
                </DialogHeader>

                <DialogFooter>
                  <Button
                    type="submit"
                    variant="destructive"
                    className="m-auto"
                    disabled={formLoading || siteFetching}
                    onClick={() => {
                      deleteSiteMember(member.id);
                    }}
                    permission="delete_sitemembers"
                  >
                    {language === "en" ? "Delete" : "حذف"}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <p>{member.role}</p>
            <p>{member.user.full_name}</p>
          </div>
        ))}
        {addSiteMembersComponent()}
      </>
    );
  };

  const checkFormChanges = () => {
    if (updatedFields === null || updatedFields === undefined) {
      setFormError(
        language === "en" ? "No changes detected" : "لم يتم الكشف عن أي تغيير"
      );
      return false;
    } else if (updatedFields.name === "") {
      setFormError(
        language === "en" ? "Site Name is required" : "اسم الموقع مطلوب"
      );
      return false;
    } else {
      return true;
    }
  };

  const updateSite = async () => {
    setFormLoading(true);
    // console.log(updatedFields);
    if (checkFormChanges()) {
      setFormError("");
      // API Call
      await editSite(id, updatedFields)
        .then((res: any) => {
          // setSite(res.data);
          setUpdatedFields(null);
          setIsEditing(false);
        })
        .catch((error: any) => {
          console.log("Error", error);
        });
    }
    setFormLoading(false);
  };

  return (
    <div dir={language === "ar" ? "rtl" : "ltr"}>
      {siteLoading && <LoadingScreen />}

      {site && (
        <Card className="w-full max-w-lg mx-auto mt-8">
          <CardHeader>
            <div className="flex justify-end">
              <Button
                onClick={() => navigate("/sites")}
                className="w-full my-4"
                variant={"secondary"}
              >
                {language === "en" ? "Back" : "عودة"}
              </Button>
            </div>
            <CardTitle>
              {language === "en" ? "Manage Site" : "إدارة الموقع"}
            </CardTitle>

            <Button
              onClick={() => setIsEditing(!isEditing)}
              className="w-fit "
              variant={isEditing ? "destructive" : "secondary"}
              permission="change_sites"
            >
              {isEditing
                ? language === "en"
                  ? "Cancel"
                  : "إلغاء"
                : language === "en"
                  ? "Edit"
                  : "تعديل"}
            </Button>
          </CardHeader>
          <CardContent>
            {setInputField(
              language === "en" ? "Site Name" : "اسم الموقع",
              "name",
              site.name
            )}
            {setInputField(
              language === "en" ? "Description" : "الوصف",
              "description",
              site.description
            )}
            {setInputField(
              language === "en" ? "Address" : "العنوان",
              "address",
              site.url_location
            )}
          </CardContent>
          <CardFooter className="flex flex-col items-start gap-4">
            {isEditing && (
              <>
                <p className="text-red-500 font-semibold ">{formError}</p>
                <Button
                  onClick={updateSite}
                  disabled={formLoading}
                  className="w-full"
                >
                  {formLoading ? (
                    <LoadingIcon />
                  ) : language === "en" ? (
                    "Update Site"
                  ) : (
                    "تحديث الموقع"
                  )}
                </Button>
              </>
            )}
          </CardFooter>

          <div className="mt-4">
            <div className="w-full">
              <CardHeader>
                <CardTitle>
                  {language === "en" ? "Site Members" : "أعضاء الموقع"}
                </CardTitle>
                <Button
                  onClick={() => setIsSiteMembersEditing(!isSiteMembersEditing)}
                  className="w-fit "
                  variant={isSiteMembersEditing ? "destructive" : "secondary"}
                  permission="add_sitemembers"
                >
                  {isSiteMembersEditing
                    ? language === "en"
                      ? "Cancel"
                      : "إلغاء"
                    : language === "en"
                      ? "Edit"
                      : "تعديل"}
                </Button>
              </CardHeader>
              <CardContent>
                {siteMembersComponent(site.site_members)}
                {/* {setMultiSelectField(language === 'en' ? 'Site Members' : 'أعضاء الموقع', 'site_members', site.site_members)} */}
              </CardContent>
            </div>
          </div>

          <div className="mt-4">
            <div className="w-full">
              <CardHeader>
                <CardTitle>
                  {language === "en"
                    ? "Associated Projects"
                    : "المشاريع المرتبطة"}
                </CardTitle>
              </CardHeader>
              <CardContent>
                {site.projects?.map((project: any) => (
                  <div
                    key={project.id}
                    className="flex justify-between items-center border-b border-gray-200 py-2"
                  >
                    <p>{project.title}</p>
                    <Button
                      variant={"link"}
                      onClick={() => navigate(`/projects/id/${project.id}`)}
                    >
                      {language === "en" ? "View Project" : "عرض المشروع"}
                    </Button>
                  </div>
                ))}
              </CardContent>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default ManageSite;
