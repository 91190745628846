import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import LoadingScreen from '../loading/loading-screen';

interface ProtectedRouteProps {
  permission?: string;
  element: React.ComponentType<any>;
  [rest: string]: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ permission, element: Component, ...rest }) => {
  const { isAuthenticated, checkPermission, isPermissionsLoading } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to={`/login?from=${encodeURIComponent(location.pathname)}`} />;
  }

  // Wait for the authentication and permissions to be fully loaded
  if (isPermissionsLoading) {
    return <LoadingScreen />;
  }

  if (permission && !checkPermission(permission)) {
    return <Navigate to="/not-authorized" />;
  }

  // If authenticated and permissions are valid, render the component
  return <Component {...rest} />;
};
const UnprotectedRoute: React.FC<ProtectedRouteProps> = ({ element: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    !isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />
  );
};

export {ProtectedRoute, UnprotectedRoute};

