import { useLanguage } from "@/components/context/LanguageContext";
import {
    Card,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import availableRoutes from "@/services/pages-route-btn-service";
import WhatIsNew from "@/components/what-is-new";
import { Button } from "@/components/ui/button";

const Home = () => {
    const navigate = useNavigate();
    const [DashboardBtns] = useState(availableRoutes(40));
    const { language } = useLanguage();
    const activeRoutes = DashboardBtns.filter(btn => btn.isActive && btn.route !== '/');
    const [permissions, setPermissions] = useState<any>();
    const [viewNews, setViewNews] = useState(false);
    useEffect(() => {
        document.title = 'Lazem GO | Home';
        const permissions = JSON.parse(sessionStorage.getItem('permissions') || '[]');
        setPermissions(permissions);
    }, []);

    

    return (
        <>
            {
                viewNews && 
                <Card>
                    <WhatIsNew />
                    <div className="flex justify-content-center">
                        <Button 
                            onClick={() => setViewNews(false)} 
                            className="w-2/5 my-4 mx-auto"

                        >
                            {language === 'en' ? 'Close' : 'إغلاق'}
                        </Button>
                    </div>
                </Card>
            }
            <div
                dir={language === 'en' ? 'ltr' : 'rtl'}
                className="flex justify-content-start flex-wrap mx-auto mt-10 gap-6 p-4"
            >
                {
                    permissions && (permissions.length === 0 || permissions === '[]') ?
                        (
                            <Card
                                className="text-center text-red-500 font-bold mt-10 w-full lg:w-1/2 mx-auto"
                            >
                                <CardHeader className="h-1/3 w-full flex justify-content-center m-auto">
                                    <CardTitle className="text-2xl font-bold flex justify-content-center m-auto">
                                        {language === 'en'
                                            ? "You do not have any permissions. Please contact your admin for access."
                                            : "ليس لديك أذونات. يرجى الاتصال بمدير النظام للحصول على الصلاحيات."
                                        }
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        )
                        :
                        activeRoutes && (
                            activeRoutes.map((btn, index) => (
                                <div key={index} className="m-auto lg:m-0">
                                    <Card
                                        className="m-3 w-64 h-64 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                                        onClick={() => navigate(btn.route)}
                                    >
                                        <CardHeader className="h-1/3 w-full flex justify-content-center m-auto">
                                            <CardTitle className="text-2xl font-bold flex justify-content-center m-auto">
                                                {language === 'en' ? btn.title_en : btn.title_ar}
                                            </CardTitle>
                                            <div className="w-fit mx-auto my-4">
                                                {btn.icon}
                                            </div>
                                        </CardHeader>
                                    </Card>
                                </div>
                            ))
                        )
                }
            </div>
        </>
    );
};

export default Home;
