import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getDepartments } from "@/services/project-service";
import { categories } from "@/services/tickets-services";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";
import { Input } from "@/components/ui/input";
import { MdDelete } from "react-icons/md";

const SupportSettings = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [isUpdated, setIsUpdated] = useState(false);
    const [searchParams] = useSearchParams();
    const queryString = searchParams.toString();
    const [categoryForm, setCategoryForm] = useState<any>(
        {
            "name": "",
            "description": "",
            "department": null,
            "tag": null,
            "is_active": false,
        }
    );

    const {
        data: departmentsData,
        isLoading: departmentsLoading,
    } = useQuery({
        queryKey: ["departments"],
        queryFn: getDepartments,
        refetchOnWindowFocus: false,
    });

    const {
        data: categoriesOptionData,
        isLoading: categoriesOptionLoading,
    } = useQuery({
        queryKey: ["categoriesOptions"],
        queryFn: categories.options,
        refetchOnWindowFocus: false,
    });


    const {
        data: categoriesData,
        isLoading: categoriesLoading,
        isRefetching: categoriesRefetching,
        refetch: categoriesRefetch,
    } = useQuery({
        queryKey: ["categories"],
        queryFn: categories.list,
        refetchOnWindowFocus: false,
    });

    const updateCategory = async (id: any, data: any) => {
        const response = await categories.update(id, data).then(() => {
            categoriesRefetch().then(() => {
                window.scrollTo(0, 0);
                setIsUpdated(true);
                setTimeout(() => {
                    setIsUpdated(false);
                }, 3000);
            }).catch((error: any) => {
                console.log('Form Error', error);
                throw error;
            })
        }).catch((error: any) => {
            console.log('Form Error', error);
            throw error;
        });
    }

    const deleteCategory = async (id: any) => {
        const response = await categories.delete(id).then((response) => {
            categoriesRefetch();
        }).catch((error: any) => {
            console.log('Form Error', error);
            throw error;
        });
    }

    const addCategory = async (data: any) => {
        const response = await categories.create(data).then(() => {
            setCategoryForm({
                "name": "",
                "description": "",
                "department": null,
                "tag": null,
                "is_active": false,
            });
            categoriesRefetch();
        }).catch((error: any) => {
            console.log('Form Error', error);
            throw error;
        });
    }



    return (
        <div
            dir={language === "en" ? "ltr" : "rtl"}
        >
            {(departmentsLoading || categoriesLoading || categoriesRefetching || categoriesOptionLoading) && <LoadingScreen />}

            <div
                className="mt-6"
            >
                <h1
                    className="text-2xl font-bold text-center"
                >
                    {language === "en" ? "Support Settings" : "اعدادات الدعم"}
                </h1>
                <div
                    className="flex justify-end space-x-2 gap-2 mx-4"
                >
                    <Button
                        onClick={() => {
                            navigate("/requests?"+queryString);
                        }}
                    >
                        {language === "en" ? "Back" : "رجوع"}
                    </Button>
                </div>
                {isUpdated && (
                    <div
                        className="text-green-500 p-2"
                    >
                        {language === "en" ? "Updated" : "تم التحديث"}
                    </div>
                )}
                <h2
                    className="text-xl font-bold text-center"
                >
                    {language === "en" ? "Categories" : "الفئات"}
                </h2>
            </div>
            <Table
                className="w-full"
            >
                <TableHeader>
                    <TableRow>
                        <TableHead>
                            {language === "en" ? "Name" : "الاسم"}
                        </TableHead>
                        <TableHead>
                            {language === "en" ? "Description" : "الوصف"}
                        </TableHead>
                        <TableHead>
                            {language === "en" ? "Department" : "القسم"}
                        </TableHead>
                        <TableHead>
                            {language === "en" ? "Tag" : "تاق"}
                        </TableHead>
                        <TableHead>
                            {language === "en" ? "Active" : "نشط"}
                        </TableHead>
                        <TableHead>
                            {language === "en" ? "Actions" : "الاجراءات"}
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {categoriesData?.data?.map((category: any) => (
                        <TableRow key={category.id}>
                            <TableCell>
                                <Input
                                    defaultValue={category.name}
                                    onChange={(e) => {
                                        queryClient.setQueryData(
                                            ["categories"],
                                            (old: any) => {
                                                return {
                                                    ...old,
                                                    data: old?.data?.map((item: any) => {
                                                        if (item.id === category.id) {
                                                            return {
                                                                ...item,
                                                                name: e.target.value,
                                                            };
                                                        }
                                                        return item;
                                                    }),
                                                };
                                            }
                                        );
                                    }}
                                />

                            </TableCell>
                            <TableCell>
                                <Input
                                    value={category.description}
                                    onChange={(e) => {
                                        queryClient.setQueryData(
                                            ["categories"],
                                            (old: any) => {
                                                return {
                                                    ...old,
                                                    data: old?.data?.map((item: any) => {
                                                        if (item.id === category.id) {
                                                            return {
                                                                ...item,
                                                                description: e.target.value,
                                                            };
                                                        }
                                                        return item;
                                                    }),
                                                };
                                            }
                                        );
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Select
                                    value={category.department}
                                    onValueChange={(value) => {
                                        queryClient.setQueryData(
                                            ["categories"],
                                            (old: any) => {
                                                return {
                                                    ...old,
                                                    data: old?.data?.map((item: any) => {
                                                        if (item.id === category.id) {
                                                            return {
                                                                ...item,
                                                                department: value,
                                                            };
                                                        }
                                                        return item;
                                                    }),
                                                };
                                            }
                                        );
                                    }
                                    }
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder={language === "en" ? "Department" : "القسم"} />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {departmentsData?.data?.map((department: any) => (
                                                <SelectItem
                                                    key={department.id}
                                                    value={department.id}
                                                >
                                                    {department.title}
                                                </SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Select
                                    value={category.tag}
                                    onValueChange={(value) => {
                                        queryClient.setQueryData(
                                            ["categories"],
                                            (old: any) => {
                                                return {
                                                    ...old,
                                                    data: old?.data?.map((item: any) => {
                                                        if (item.id === category.id) {
                                                            return {
                                                                ...item,
                                                                tag: value,
                                                            };
                                                        }
                                                        return item;
                                                    }),
                                                };
                                            }
                                        );
                                    }}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder={language === "en" ? "Tag" : "تاق"} />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {categoriesOptionData?.data?.actions?.POST?.tag?.choices?.map((tag: any) => (
                                                <SelectItem
                                                    key={tag.value}
                                                    value={tag.value}
                                                >
                                                    {tag.display_name}
                                                </SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Select
                                    value={category.is_active ? "true" : "false"}
                                    onValueChange={(value) => {
                                        queryClient.setQueryData(
                                            ["categories"],
                                            (old: any) => {
                                                return {
                                                    ...old,
                                                    data: old?.data?.map((item: any) => {
                                                        if (item.id === category.id) {
                                                            return {
                                                                ...item,
                                                                is_active: value === "true" ? true : false,
                                                            };
                                                        }
                                                        return item;
                                                    }),
                                                };
                                            }
                                        );
                                    }}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder={language === "en" ? "Active" : "نشط"} />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem
                                                value={"true"}
                                            >
                                                {language === "en" ? "Yes" : "نعم"}
                                            </SelectItem>
                                            <SelectItem
                                                value={"false"}
                                            >
                                                {language === "en" ? "No" : "لا"}
                                            </SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <div
                                    className="flex items-center space-x-2 gap-2"
                                >
                                    <Button
                                        onClick={() => {
                                            updateCategory(category.id, category);
                                        }}
                                    >
                                        {language === "en" ? "Update" : "تحديث"}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            deleteCategory(category.id);
                                        }}
                                        variant={"destructive"}
                                    >
                                        <MdDelete 
                                            size={20}
                                        />
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell>
                            <Input
                                placeholder={language === "en" ? "Name" : "الاسم"}
                                value={categoryForm.name}
                                onChange={(e) => {
                                    setCategoryForm({
                                        ...categoryForm,
                                        name: e.target.value,
                                    });
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                placeholder={language === "en" ? "Description" : "الوصف"}
                                value={categoryForm.description}
                                onChange={(e) => {
                                    setCategoryForm({
                                        ...categoryForm,
                                        description: e.target.value,
                                    });
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Select
                                value={categoryForm.department}
                                onValueChange={(value) => {
                                    setCategoryForm({
                                        ...categoryForm,
                                        department: value
                                    });
                                }}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder={language === "en" ? "Department" : "القسم"} />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {departmentsData?.data?.map((department: any) => (
                                            <SelectItem
                                                key={department.id}
                                                value={department.id}
                                            >
                                                {department.title}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </TableCell>
                        <TableCell>
                            <Select
                                value={categoryForm.tag}
                                onValueChange={(value) => {
                                    setCategoryForm({
                                        ...categoryForm,
                                        tag: value,
                                    });
                                }}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder={language === "en" ? "Tag" : "الوسم"} />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {categoriesOptionData?.data?.actions?.POST?.tag?.choices?.map((tag: any) => (
                                            <SelectItem
                                                key={tag.value}
                                                value={tag.value}
                                            >
                                                {tag.display_name}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </TableCell>
                        <TableCell>
                            <Select
                                value={categoryForm.is_active ? "true" : "false"}
                                onValueChange={(value) => {
                                    setCategoryForm({
                                        ...categoryForm,
                                        is_active: value === "true" ? true : false,
                                    });
                                }}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder={language === "en" ? "Active" : "نشط"} />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem
                                            value={"true"}
                                        >
                                            {language === "en" ? "Yes" : "نعم"}
                                        </SelectItem>
                                        <SelectItem
                                            value={"false"}
                                        >
                                            {language === "en" ? "No" : "لا"}
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </TableCell>
                        <TableCell>
                            <Button
                                onClick={() => {
                                    addCategory(categoryForm);
                                }}
                            >
                                {language === "en" ? "Add" : "اضافة"}
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </div>
    );
}

export default SupportSettings;