import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import { useRef, useState } from "react";
import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "@/components/ui/card";
import { useTheme } from "@/components/theme-provider";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { EmailTemplates } from "@/services/notification-services";
import { Textarea } from "@/components/ui/textarea";
import { useQuery } from "@tanstack/react-query";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";

const EmailBuilder = () => {
  const { theme } = useTheme();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const emailEditorRef = useRef<EditorRef | null>(null);
  const [preview, setPreview] = useState(false);
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [formError, setFormError] = useState("");
  const [description, setDescription] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [loading, setLoading] = useState(false);
  
  const exportHtml = () => {
    console.log("subject", subject);
    if (!checkInputs()) {
      return;
    }
    setFormError("");
    setLoading(true);
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const { html, design } = data;
      let template_data = {
        title: title,
        subject: subject,
        body: html,
        body_json: design,
        description: description,
      }
      EmailTemplates.create(template_data).then(() => {
        setLoading(false);
        navigate("/system-notification/emails");
      }).catch((error) => {
        console.log("error", error);
        setLoading(false);
        setFormError(language === "en" ? "Failed to save email template" : "فشل في حفظ قالب البريد الإلكتروني");
      }
      );

    });
  };
  const {
    data: emailTemplatesVars,
    isLoading:emailTemplatesVarsLoading
  } = useQuery({
    queryKey: ["emailTemplatesVars"],
    queryFn: EmailTemplates.list_email_vars,
    refetchOnWindowFocus: false,
  });


  const checkInputs = () => {
    if (!subject) {
      setFormError(language === "en" ? "Please enter the email subject" : "الرجاء إدخال موضوع البريد الإلكتروني");
      return false;
    }
    else if (!title) {
      setFormError(language === "en" ? "Please enter the email template title" : "الرجاء إدخال عنوان قالب البريد الإلكتروني");
      return false;
    }
    return true;
  };

  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (preview) {
      unlayer?.hidePreview();
      setPreview(false);
    } else {
      unlayer?.showPreview({ device: "desktop" });
      setPreview(true);
    }
  };

  const onReady: EmailEditorProps["onReady"] = (unlayer) => {
    console.log("onReady", unlayer);
  };

  return (
    <div
      dir={language === "en" ? "ltr" : "rtl"}
    >
      {emailTemplatesVarsLoading && <LoadingScreen />}
      {loading && 
        <div
          className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center"
        >
          <LoadingIcon />
        </div>
      }
      <div>
        <Card>
          <CardHeader>
            <div>
              <Button
                onClick={() => navigate('/system-notification/emails')}
                className="w-full my-4"
                variant={"secondary"}
              >
                {language === 'en' ? 'Back' : 'عودة'}
              </Button>

            </div>
            <CardTitle>
              {language === "en"
                ? "Email Builder"
                : "برنامج بناء البريد الإلكتروني"}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <CardDescription>
              {formError &&
                <p className="text-red-500 text-sm">{formError}</p>
              }
            </CardDescription>
          </CardContent>
          <CardFooter className="flex flex-col justify-center space-x-4">
            <div
            >
              {preview && (
                <Button
                  onClick={exportHtml}
                  className="mx-6"
                >
                  {language === "en" ? "Save" : "حفظ"}
                </Button>
              )}

              <Button
                onClick={togglePreview}
                variant={preview ? "destructive" : "default"}
                className="mx-6"
              >
                {preview
                  ? language === "en"
                    ? "Build view"
                    : "عرض البناء"
                  : language === "en"
                    ? "Preview"
                    : "معاينة"}
              </Button>
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email subject" : "موضوع البريد الإلكتروني"}
              </Label>
              <Input
                className="w-full my-2 text-start"
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email template title" : "اسم القالب"}
              </Label>
              <Input
                className="w-full my-2 text-start"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email template description" : "شرح القالب"}
              </Label>
              <Textarea
                className="w-full my-2 text-start"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            {
              !emailTemplatesVarsLoading &&
              <div
                className="w-full"
              >
                <div

                >
                  <Select
                    onValueChange={(value: any) => {
                      setSelectedTemplate(value)
                    }}
                    dir={language === "en" ? "ltr" : "rtl"}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={language === "en" ? "Select variable" : "اختر المتغير"} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {emailTemplatesVars?.map((item: any, index: any) => (
                          <SelectItem
                            key={index}
                            value={item.vars}
                          >
                            {item.description}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {
                    selectedTemplate &&
                    <>
                      <div
                        className="text-start w-full mt-4"
                      >
                        {language === "en" ? "If you want to use a variable, put it inside 2 curly brackets like this: {{variable_name}}" : "إذا كنت ترغب في استخدام متغير ، ضعه داخل 2 قوس متعرج مثل هذا: {{اسم المتغير}}"}
                      </div>                    
                      <div
                        className="text-start w-full flex flex-wrap "
                      >
                        {
                          selectedTemplate.map((item: any, index: any) => (
                            <div
                              key={"vvars" + index}
                              className="flex flex-col my-4 w-full lg:w-2/5"
                            >

                              <div
                                className="my-2"
                              >
                                {language === "en" ? "Var to use" : "اسم المتغير"}: {item.name}
                              </div>
                              <div>
                                {language === "en" ? "Description" : "الوصف"}: {item.description}
                              </div>                
                            </div>
                          ))
                        }
                      </div>
                    </>
                  }
                </div>
              </div>
            }
          </CardFooter>

          <EmailEditor
            ref={emailEditorRef}
            onReady={onReady}
            options={{
              version: "latest",
              appearance: {
                theme: theme === "dark" ? "dark" : "light",
              },
            }}
          />
        </Card>

      </div>
    </div>
  );
};

export default EmailBuilder;
