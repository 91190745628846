import { useLanguage } from "@/components/context/LanguageContext";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import LoadingScreen from "@/components/loading/loading-screen";
import { Button } from "@/components/ui/button";
import TaskTypeForm from "@/components/task/task-type-form";
import TaskTypeConsent from "@/components/task/task-type-consent";
import { getTask, editTask } from "@/services/task-services";
import LoadingIcon from "@/components/loading/loading-icon";
import { useQuery } from "@tanstack/react-query";
import { formFiles } from "@/services/forms-service";
import { Label } from "@/components/ui/label";

const TaskView = () => {
  const { id } = useParams<{ id: string }>();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const [tasks, setTasks] = useState<any>();

  useEffect(() => {
    document.title = "Lazem GO - Task View";
  }, []);

  const fetchTasks = async () => {
    await getTask(id)
      .then((response) => {
        // console.log('Tasks',response.data);
        setTasks(response.data);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  const { isLoading: tasksLoading } = useQuery({
    queryKey: ["tasks", id],
    queryFn: fetchTasks,
    refetchOnWindowFocus: false,
  });

  function formatDate(isoDate: any) {
    const date = new Date(isoDate);
    let formattedDateTime = date.toLocaleDateString("en-SA", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
      hour: "2-digit",
      minute: "2-digit",
    });

    // Remove the "at" if it exists
    formattedDateTime = formattedDateTime.replace(" at ", " ");

    return formattedDateTime;
  }

  const handelSubmit = () => {
    setFormLoading(true);
    editTask(id, { approval_response: "approved" })
      .then((response) => {
        // console.log(response);
        navigate("/tasks");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  const openFileInNewTab = async (id: any) => {
    await formFiles.get(id).then((response) => {
      window && window.open(response.data.file, "_blank");
    }
    );
  }

  const dependentTask = (dependent_task: any) => {
    // console.log('dependent_task', dependent_task);
    // This is the dependent task that is displayed on the task view page
    // Depending on the task type, the dependent task will have different fields
    // I will make the form view as an example and you can add the other types
    // The design is not final and you can change it as you like
    if (!dependent_task) return null;
    return (
      dependent_task?.map((dependent_task: any) => (
        <>
          <Card
          className="w-full mx-auto mt-4 max-w-xl p-4">

            <div
              className="flex flex-wrap justify-between gap-4 w-full"
            >
              <div className="flex flex-col justify-start items-start w-2/5 ">
                {language === "en" ? "Title" : "العنوان"}
                <Label>{dependent_task?.title}</Label>
              </div>
              <div className="flex flex-col justify-start items-start w-2/5 ">
                {language === "en" ? "Status" : "الحالة"}
                <Label>{dependent_task?.status}</Label>
              </div>
              <div className="flex flex-col justify-start items-start w-full">
                {language === "en" ? "Description" : "الوصف"}
                <Label>{dependent_task?.description}</Label>
              </div>
              <div className="flex flex-col justify-start items-start w-full">
                {language === "en" ? "Submetted By" : "تم الإرسال بواسطة"}
                <Label>{dependent_task?.submission?.created_by?.full_name || dependent_task?.updated_by?.full_name}</Label>
              </div>
            </div>

            {
              dependent_task?.type === "form" ?
                <Card
                  className="w-full mx-auto mt-4 max-w-xl p-4"
                >
                  <CardHeader>
                    <h1>
                      {language === "en" ? "Filled Form Details" : "تفاصيل النموذج المعبأ"}
                    </h1>
                  </CardHeader>
                  {
                    dependent_task?.submission?.answers?.map((answer: any) => {
                      return (
                        (answer?.question_type === "file" || answer?.question_type === "camera")
                          ? (
                            <div className="flex flex-col justify-start items-start w-full my-4">
                              <p>{answer?.question}</p>
                              {answer?.files?.map((file: any) => {
                                return (
                                  <div
                                    key={file}
                                    className="flex flex-row justify-between items-center w-full"
                                  >
                                    <Button
                                      onClick={() => openFileInNewTab(file)}
                                      variant={"secondary"}
                                    >{
                                        language === "en" ? "View File" : "عرض الملف"}</Button>
                                  </div>
                                )
                              })}
                            </div>
                          )
                          :
                          answer.question_type === "checkbox" || answer.question_type === "multi-select" ? (
                            <div className="flex flex-col justify-start items-start w-full mt-6">
                              <p>{answer.question}</p>
                              {answer?.answer?.map((ans: any) => {
                                return (
                                  <Label key={ans}>{ans}</Label>
                                )
                              })}
                            </div>
                          )
                            :
                            (
                              <div className="flex flex-col justify-start items-start w-full mt-6">
                                <p>{answer?.question}</p>
                                <p>{answer?.answer}</p>
                              </div>
                            )
                      )
                    })
                  }
                </Card>

                :
                dependent_task?.type === "approval" ?
                  <Card
                    className="w-full mx-auto mt-4 max-w-xl p-4"
                  >
                    <CardHeader>
                      <h1>
                        {language === "en" ? "Approval Details" : "تفاصيل الموافقة"}
                      </h1>
                    </CardHeader>
                    <CardContent>
                      <div className="flex flex-col justify-start items-start w-full">
                        {language === "en" ? "Approval Response" : "رد الموافقة"}
                        <p>{dependent_task?.approval_response}</p>
                      </div>
                    </CardContent>
                  </Card>
                  :
                  dependent_task?.type === "consent" ?
                    <Card
                      className="w-full mx-auto mt-4 max-w-xl p-4"
                    >
                      <CardHeader>
                        <h1>
                          {language === "en" ? "Consent Details" : "تفاصيل الموافقة"}
                        </h1>
                      </CardHeader>
                      <CardContent>
                        <div className="flex flex-col justify-start items-start w-full">
                          {language === "en" ? "Consent Response" : "رد الموافقة"}
                          <p>{dependent_task?.approval_response}</p>
                        </div>
                      </CardContent>
                    </Card>
                    :
                    dependent_task?.type === "submit" ?
                      <Card
                        className="w-full mx-auto mt-4 max-w-xl p-4"
                      >
                        <CardHeader>
                          <h1>
                            {language === "en" ? "Submit Details" : "تفاصيل الإرسال"}
                          </h1>
                        </CardHeader>
                        <CardContent>
                          <div className="flex flex-col justify-start items-start w-full">
                            {language === "en" ? "Submit Response" : "رد الإرسال"}
                            <p>{dependent_task?.approval_response}</p>
                          </div>
                        </CardContent>
                      </Card>
                      : null
            }
          </Card>
        </>
      ))


    );
  };
  const projectView = (project: any) => {
    return (
      <>
        <div className="flex flex-col justify-start items-start w-2/5 ">
          {language === "en" ? "Title" : "العنوان"}
          <p>{project?.title}</p>
        </div>
        <div className="flex flex-col justify-start items-start w-2/5 ">
          {language === "en" ? "Status" : "الحالة"}
          <p>{project?.status?.title}</p>
        </div>
        <div className="flex flex-col justify-start items-start w-2/5 ">
          {language === "en" ? "Start Date" : "تاريخ البدء"}
          <p>{formatDate(project?.start_date)}</p>
        </div>
        <div className="flex flex-col justify-start items-start w-2/5 ">
          {language === "en" ? "End Date" : "تاريخ الانتهاء"}
          <p>{formatDate(project?.end_date)}</p>
        </div>
        <div className="flex flex-col justify-start items-start w-2/5 ">
          {language === "en" ? "Manager" : "المدير"}
          <p>{project?.project_manager?.full_name}</p>
        </div>
        <div className="flex flex-col justify-start items-start w-2/5 ">
          {language === "en" ? "Site" : "الموقع"}
          <p>{project?.site?.name}</p>
          <div>
            <Button
              onClick={() => navigate(project?.site?.url_location)}
              variant={"link"}
            >
              {language === "en" ? "View Site" : "عرض الموقع"}
            </Button>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start w-full ">
          {language === "en" ? "Support Team" : "فريق الدعم"}
          <div
            className="flex flex-col justify-start items-start w-full gap-4"
          >
            {project?.support_team?.map((team: any) => (
              <p
                key={team.id}
                className=""
              >
                {team.full_name}
              </p>
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-start items-start w-full">
          {language === "en" ? "Description" : "الوصف"}
          <p>{project?.description}</p>
        </div>
      </>
    );
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {tasksLoading && <LoadingScreen />}
      {tasks && (
        <>
          <Button
            onClick={() => navigate("/tasks")}
            variant={"secondary"}
            className="w-full mx-auto mt-4 max-w-xl"
          >
            {language === "en" ? "Back" : "العودة"}
          </Button>
          {tasks?.dependent_task !== null ? (
            <Card className="w-full mx-auto mt-4 max-w-xl">
              <CardHeader>
                <h1>
                  {language === "en" ? "Previous Tasks" : "المهمات السابقة"}
                </h1>
              </CardHeader>
              <CardContent className="flex flex-wrap justify-between items-end w-full gap-4">
                {dependentTask(tasks?.dependent_task)}
              </CardContent>
            </Card>
          ) : null}
          {typeof tasks.project === "object" ? (
            <Card className="w-full mx-auto mt-4 max-w-xl">
              <CardHeader>
                <h1>
                  {language === "en" ? "Project Details" : "تفاصيل المشروع"}
                </h1>
              </CardHeader>
              <CardContent className="flex flex-wrap justify-between items-end w-full gap-4">
                {projectView(tasks?.project)}
              </CardContent>
            </Card>
          ) : null}

          <Card className="w-full mx-auto mt-4 max-w-xl">
            <CardHeader
              className="text-center"
            >
              <div className="w-full mt-6">
                {language === "en" ? "Task Details" : "تفاصيل المهمة"}
                <CardTitle>{tasks.title}</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="flex flex-wrap justify-between items-end w-full gap-4">
              <div className="flex flex-col justify-start items-start w-2/5 ">
                {language === "en" ? "Status" : "الحالة"}
                <p>{tasks.status}</p>
              </div>
              <div className="flex flex-col justify-start items-start w-2/5 ">
                {language === "en" ? "Type" : "النوع"}
                <p>{tasks.type}</p>
              </div>
              <div className="flex flex-col justify-start items-start w-full">
                {language === "en" ? "Description" : "الوصف"}
                <p>{tasks.description}</p>
              </div>
              <div className="flex flex-col justify-start items-start w-3/5 ">
                {language === "en" ? "Start Date" : "تاريخ البدء"}
                <p>{formatDate(tasks.start_date)}</p>
              </div>
              <div className="flex flex-col justify-start items-start w-3/5 ">
                {language === "en" ? "End Date" : "تاريخ الانتهاء"}
                <p>{formatDate(tasks.end_date)}</p>
              </div>
              <div className="flex flex-col justify-start items-start w-full">
                {language === "en" ? "Submetted By" : "تم الإرسال بواسطة"}
                <Label>{tasks?.submission?.created_by?.full_name || tasks?.updated_by?.full_name}</Label>
              </div>
            </CardContent>
            <CardContent className="">
              {tasks.status === "completed" ? (
                <>
                  <p className="text-center text-green-500 font-semibold text-lg">
                    {language === "en"
                      ? "Task is completed"
                      : "تم انجاز المهمة"}
                  </p>
                  {
              tasks?.type === "form" ?
                <Card
                  className="w-full mx-auto mt-4 max-w-xl p-4"
                >
                  <CardHeader>
                    <h1>
                      {language === "en" ? "Filled Form Details" : "تفاصيل النموذج المعبأ"}
                    </h1>
                  </CardHeader>
                  {
                    tasks?.submission?.answers?.map((answer: any) => {
                      return (
                        (answer.question_type === "file" || answer.question_type === "camera")
                          ? (
                            <div className="flex flex-col justify-start items-start w-full my-4">
                              <p

                              >
                                 {language === "en" ? "Question" : "السؤال"}: {answer.question} 
                              </p>
                              <p
                                className="mt-2"
                              >
                                {language === "en" ? "Answer" : "الجواب"}:
                              </p>
                              {answer.files.map((file: any) => {
                                  return (
                                    <div
                                      key={file}
                                      className="flex flex-row justify-between items-center w-full"
                                    >
                                      <Button
                                        onClick={() => openFileInNewTab(file)}
                                        variant={"secondary"}
                                      >{
                                          language === "en" ? "View File" : "عرض الملف"}</Button>
                                    </div>
                                  )
                                })}
                            </div>
                          )
                          :
                          answer.question_type === "checkbox" || answer.question_type === "multiselect" ? (
                            <div className="flex flex-col justify-start items-start w-full mt-6">
                              <p>
                                {language === "en" ? "Question" : "السؤال"}: {answer.question}
                              </p>
                              <p>
                                {language === "en" ? "Answer" : "الجواب"}:
                              </p>
                              {answer.answer.map((ans: any) => {
                                return (
                                  <Label key={ans}>{ans}</Label>
                                )
                              })}
                            </div>
                          )
                          :
                          answer.question_type === "list of sites" ? (
                            <>
                              <div className="flex flex-col justify-start items-start w-full mt-6 ">
                                <p>
                                  {language === "en" ? "Question" : "السؤال"}: {answer.question}
                                </p>
                                <p>
                                  {language === "en" ? "Answer" : "الجواب"}:
                                </p>
                                <Button
                                  variant={"link"}
                                  onClick={() => navigate(answer.answer[0].endpoint)}
                                >
                                  {answer.answer[0].name}
                                </Button>
                              </div>
                            </>
                          )
                            :
                            (
                              <div className="flex flex-col justify-start items-start w-full mt-6">
                                <p>
                                  {language === "en" ? "Question" : "السؤال"}: {answer.question}
                                </p>
                                <p>
                                  {language === "en" ? "Answer" : "الجواب"}:
                                </p>
                              </div>
                            )
                      )
                    })
                  }
                </Card>

                :
                tasks?.type === "approval" ?
                  <Card
                    className="w-full mx-auto mt-4 max-w-xl p-4"
                  >
                    <CardHeader>
                      <h1>
                        {language === "en" ? "Approval Details" : "تفاصيل الموافقة"}
                      </h1>
                    </CardHeader>
                    <CardContent>
                      <div className="flex flex-col justify-start items-start w-full">
                        {language === "en" ? "Approval Response" : "رد الموافقة"}
                        <p>{tasks.approval_response}</p>
                      </div>
                    </CardContent>
                  </Card>
                  :
                  tasks?.type === "consent" ?
                    <Card
                      className="w-full mx-auto mt-4 max-w-xl p-4"
                    >
                      <CardHeader>
                        <h1>
                          {language === "en" ? "Consent Details" : "تفاصيل الموافقة"}
                        </h1>
                      </CardHeader>
                      <CardContent>
                        <div className="flex flex-col justify-start items-start w-full">
                          {language === "en" ? "Consent Response" : "رد الموافقة"}
                          <p>{tasks.approval_response}</p>
                        </div>
                      </CardContent>
                    </Card>
                    :
                    tasks.type === "submit" ?
                      <Card
                        className="w-full mx-auto mt-4 max-w-xl p-4"
                      >
                        <CardHeader>
                          <h1>
                            {language === "en" ? "Submit Details" : "تفاصيل الإرسال"}
                          </h1>
                        </CardHeader>
                        <CardContent>
                          <div className="flex flex-col justify-start items-start w-full">
                            {language === "en" ? "Submit Response" : "رد الإرسال"}
                            <p>{tasks.approval_response}</p>
                          </div>
                        </CardContent>
                      </Card>
                      : null
            }
                </>
              ) : (
                <>
                  {tasks.type === "form" ? (
                    <TaskTypeForm id={tasks.form} />
                    
                  ) : tasks.type === "approval" ? (
                    <TaskTypeConsent id={id} />
                  ) : tasks.type === "consent" ? (
                    <TaskTypeConsent id={id} />
                  ) : tasks.type === "submit" ? (
                    <div>
                      <Button
                        onClick={() => handelSubmit()}
                        className="w-fit mx-auto"
                        disabled={formLoading}
                      >
                        {formLoading ? (
                          <LoadingIcon />
                        ) : language === "en" ? (
                          "Submit"
                        ) : (
                          "إرسال"
                        )}
                      </Button>
                    </div>
                  ) : null}
                </>
              )}
            </CardContent>
            <CardFooter></CardFooter>
          </Card>
        </>
      )}
    </div>
  );
};

export default TaskView;
