import ApiUrl from "./api-url";
import axios, { isCancel } from 'axios';
import { ApiHeaderOptions } from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";


const EmailTemplates = {
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/notifications/email_templates/`;
        return axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " email template", "medium");
        });
    },
    list: async () => {
        const url = `${ApiUrl}/v1/notifications/email_templates/`;
        return axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " list email template", "high");
            throw error;
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/notifications/email_templates/${id}/`;
        return axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " get email template", "high");
            throw error;
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/notifications/email_templates/${id}/`;
        return axios.patch(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " update email template", "medium");
            throw error;
        });
    },
    list_email_vars: async () => {
        const url = `${ApiUrl}/v1/notifications/email_templates_vars/`;
        return axios.get(url, ApiHeaderOptions()).then((response) => {
            console.log("vars response", response.data);
            return response.data;
        }).catch((error) => { 
            errorHandlingService(error, " list email vars", "high");
            throw error;
        });
    }
}

const SystemNotifications = {
    list: async () => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " list system notification", "high");
            throw error;
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/${id}/`;
        return axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " get system notification", "high");
            throw error;
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " create system notification", "medium");
            throw error;
        });
    },
    options: async () => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.options(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => { 
            errorHandlingService(error, " options system notification", "high");
            throw error;
        });
    },
    triggers: async () => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.options(url, ApiHeaderOptions()).then((response) => {
            console.log("response", response?.data?.actions?.POST?.trigger?.choices);
            return response?.data?.actions?.POST?.trigger?.choices;
        }).catch((error) => {
            errorHandlingService(error, " triggers system notification", "medium");
            throw error;
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/${id}/`;
        return axios.patch(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " update system notification", "medium");
            throw error;
        });
    },
    delete: async (id: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/${id}/`;
        return axios.delete(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " delete system notification", "medium");
            throw error;
        });
    }
}


export { EmailTemplates, SystemNotifications };