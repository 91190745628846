import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import LoadingIcon from "@/components/loading/loading-icon";
import { getStage, updateStage } from "@/services/project-service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";

const EditProjectStage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [updatedFields, setUpdatedFields] = useState<any>();
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    document.title = "Lazem GO - Edit Project Stage";
  }, []);

  const {
    data: stages,
    isLoading: stagesLoading,
    isFetching: stageFetching,
    refetch: refetchStages,
  } = useQuery({
    queryKey: ["edit stages"],
    queryFn: () => getStage(id),
    refetchOnWindowFocus: false,
  });

  const checkForm = () => {
    if (stages?.data.title === "") {
      setFormError(language === "en" ? "Title is required" : "العنوان مطلوب");
      return false;
    } else if (!updatedFields) {
      setFormError(
        language === "en" ? "No changes detected" : "لا توجد تغييرات"
      );
      return false;
    }
    return true;
  };

  const handelUpdate = async () => {
    if (checkForm()) {
      setFormLoading(true);
      await updateStage(id, updatedFields)
        .then(() => {
          setFormError("");
          setFormLoading(false);
          refetchStages();
          // navigate("/projects/settings");
        })
        .catch((error) => {
          setFormLoading(false);
          console.log("Error", error);
        });
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center h-full"
      dir={language === "en" ? "ltr" : "rtl"}
    >
      {(stagesLoading || stageFetching) && <LoadingScreen />}

      {
        <Card className="w-full md:w-1/2">
          <CardHeader>
            <Button
              onClick={() => navigate("/projects/settings")}
              className="w-full my-4"
              variant={"secondary"}
            >
              {language === "en" ? "Back" : "عودة"}
            </Button>
            <CardTitle>
              {language === "en" ? "Edit Stage" : "تعديل المرحلة"}
            </CardTitle>
          </CardHeader>
          <CardContent className="text-start">
            <div className="">
              <div className="mb-4">
                <Label className="mb-4">
                  {language === "en" ? "Title" : "العنوان"}
                </Label>
                <Input
                  value={stages?.data.title}
                  onChange={(e) => {
                    queryClient.setQueryData(["edit stages"], (oldData: any) => {
                      return {
                        ...oldData,
                        data: {
                          ...oldData.data,
                          title: e.target.value,
                        },
                      };
                    });
                    setUpdatedFields({
                      ...updatedFields,
                      title: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-4">
                <Label className="mb-4">
                  {language === "en" ? "Description" : "الوصف"}
                </Label>
                <Input
                  value={stages?.data.description}
                  onChange={(e) => {
                    queryClient.setQueryData(["edit stages"], (oldData: any) => {
                      return {
                        ...oldData,
                        data: {
                          ...oldData.data,
                          description: e.target.value,
                        },
                      };
                    });
                    setUpdatedFields({
                      ...updatedFields,
                      description: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mb-4">
                <Label className="mb-4">
                  {language === "en" ? "Index" : "الفهرس"}
                </Label>
                <Input
                  value={stages?.data.index}
                  type="number"
                  onChange={(e) => {
                    queryClient.setQueryData(["edit stages"], (oldData: any) => {
                      return {
                        ...oldData,
                        data: {
                          ...oldData.data,
                          index: e.target.value,
                        },
                      };
                    });
                    setUpdatedFields({
                      ...updatedFields,
                      index: e.target.value,
                    });
                  }}
                />
              </div>
              {formError !== "" ? (
                <div className="mb-4 text-red-500">{formError}</div>
              ) : null}
              <div className="flex justify-end">
                <Button
                  onClick={handelUpdate}
                  disabled={formLoading}
                  className="w-full"
                  
                >
                  {formLoading ? (
                    <>
                      <LoadingIcon />
                    </>
                  ) : language === "en" ? (
                    "Update"
                  ) : (
                    "تحديث"
                  )}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      }
    </div>
  );
};

export default EditProjectStage;
