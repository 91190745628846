import ApiUrl from "./api-url";
import axios, {isCancel} from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";


const createStage = async (data:any) => {
    const url = `${ApiUrl}/v1/projects/stages/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " create stage", "medium");
        throw error;
    });
}

const getStages = async () => {
    const url = `${ApiUrl}/v1/projects/stages/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " list stages", "high");
        throw error;
    });
}

const getStage = async (id:any) => {
    const url = `${ApiUrl}/v1/projects/stages/${id}/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " get stage", "high");
        throw error;
    });
}

const updateStage = async (id:any, data:any) => {
    const url = `${ApiUrl}/v1/projects/stages/${id}/`;
    return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " update stage", "medium");
        throw error
    });
}

const getProjectManagers = async () => {
    const url = `${ApiUrl}/v1/core/users/simple/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " get project managers", "high");
        throw error;
    });
}

const getProjectSpportTeams = async () => {
    const url = `${ApiUrl}/v1/core/users/simple/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " get project support teams", "high");
        throw error;
    });
}

const getStatuses = async () => {
    const url = `${ApiUrl}/v1/projects/status/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " list statuses", "high");
        throw error;
    });
}

const createStatus = async (data:any) => {
    const url = `${ApiUrl}/v1/projects/status/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " create status", "medium");
        throw error;
    });
}

const getTaskStages = async () => {
    const url = `${ApiUrl}/v1/projects/task_stages/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " list task stages", "high");
        throw error;
    });
}

const getTaskTemplates = async () => {
    const url = `${ApiUrl}/v1/projects/task_templates/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " list task templates", "high");
        throw error;
    });
}

const createProjectType = async (data:any) => {
    const url = `${ApiUrl}/v1/projects/types/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " create project type", "medium");
        throw error;
    });
}

const getProjectType = async () => {
    const url = `${ApiUrl}/v1/projects/types/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " list project types", "high");
        throw error;
    });
}

const projects = {
    list : async () => {
        const url = `${ApiUrl}/v1/projects/projects/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " list projects", "high");
            throw error;
        });
    },

    create : async (data:any) => {
        const url = `${ApiUrl}/v1/projects/projects/`;
        return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " create project", "medium");
            throw error;
        });
    },
    get : async (id:any) => {
        const url = `${ApiUrl}/v1/projects/projects/${id}/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get project", "high");
            throw error;
        });
    },
    update : async (id:any, data:any) => {
        const url = `${ApiUrl}/v1/projects/projects/${id}/`;
        return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " update project", "medium");
            throw error;
        });
    }
}

const projectTypes = {
    list : async () => {
        const url = `${ApiUrl}/v1/projects/types/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " list project types", "high");
            throw error;
        });
    },

    create : async (data:any) => {
        const url = `${ApiUrl}/v1/projects/types/`;
        return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " create project type", "medium");
            throw error;
        });
    },
    get : async (id:any) => {
        const url = `${ApiUrl}/v1/projects/types/${id}/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get project type", "high");
            throw error;
        });
    },
    update : async (id:any, data:any) => {
        const url = `${ApiUrl}/v1/projects/types/${id}/`;
        return await axios.put(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " update project type", "medium");
            throw error;
        });
    },
    options : async () => {
        const url = `${ApiUrl}/v1/projects/types/`;
        return await axios.options(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get project type options", "high");
            throw error;
        });
    }
}

const getDepartments = async () => {
    const url = `${ApiUrl}/v1/core/departments/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " list departments", "high");
        throw error;
    });
}

const getDepartment = async (id:any) => {
    const url = `${ApiUrl}/v1/core/departments/${id}/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " get department", "high");
        throw error;
    });
}

const createDepartment = async (data:any) => {
    const url = `${ApiUrl}/v1/core/departments/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " create department", "medium");
        throw error;
    });
}

const updateDepartment = async (id:any, data:any) => {
    const url = `${ApiUrl}/v1/core/departments/${id}/`;
    return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " update department", "medium");
        throw error;
    });
}
const comments = {
    list : async () => {
        const url = `${ApiUrl}/v1/projects/comments/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " list comments", "high");
            throw error;
        });
    },

    create : async (data:any) => {

        const url = `${ApiUrl}/v1/projects/comments/`;
        return await axios.post(url, data, ApiHeaderOptions(undefined)).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " create comment", "medium");
            throw error;
        });
    },
    get : async (id:any) => {
        const url = `${ApiUrl}/v1/projects/comments/${id}/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get comment", "high");
            throw error;
        });
    },
    update : async (id:any, data:any) => {
        const url = `${ApiUrl}/v1/projects/comments/${id}/`;
        return await axios.put(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " update comment", "medium");
            throw error;
        });
    }
}

export { 
     createStage,
     createStatus,
     getStages,
     getStatuses,
     getProjectManagers,
     getProjectSpportTeams,
     getTaskStages,
     getTaskTemplates,
     createProjectType,
     getProjectType,
     getDepartments,
     createDepartment,
     getDepartment,
     getStage,
     updateStage,
     updateDepartment,
     projectTypes,
     projects,
     comments
    };